import React, { useEffect, useState } from "react";
import './App.css'

function App() { 
  const [size, setSize] = useState(43* window.innerHeight/window.innerWidth + 7)

  const updateSize = () => {
    setSize(43* window.innerHeight/window.innerWidth + 7)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <div id="hero">
      <div id="nav">
        <a href="/">
          <img id="logo" src='/logo.png' alt="Logo"/>
        </a>
        
        <div id="media">
          <a href="https://twitter.com/thehobocompany" target="_blank" rel="noreferrer" title="Twitter">
            <img src='/twitter.png' alt="Twitter" />
          </a>

          <a href="https://www.instagram.com/thehobocompany/" target="_blank" rel="noreferrer" title="Instagram">
            <img src='/instagram.png' alt="Instagram" />
          </a>

          <a href="https://discord.gg/mrvxZcpM9K" target="_blank" rel="noreferrer" title="Discord">
            <img src='/discord.png' alt="Dsicord" />
          </a>
        </div>
      </div>

      {/* <div id="hobo">HÖBO</div> */}

      <img id="meditate" src='/meditate.gif' alt="Meditate" style={{width: `${size}%`}}/>

      {/* <div class="footnote">Somewhere in the metaverse...</div> */}
    </div>
  )
}

export default App;
